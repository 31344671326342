<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-pen"></i> แก้ไขสัญญาซัพพลายเออร์</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(editHandler)">
              <form-supplier-contact :item="item" :suppliers="suppliers"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/supplier-contract'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button type="submit" variant="primary" class="style_btn" v-if="permission.actionData.flag_update"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from '../../../util/config';
import DateUtils from '../../../util/dateUtils';
import StringUtils from '../../../util/StringUtils';
import formSupplierContact from './form.supplier_contact';
export default {
  name: 'supplier_contact-view-page-supplier_contact-edit',
  data () {
    return {
      permission:{
        appSlug: 'supplier-contract',
        actionData: {}
      },
      item: {},
      suppliers: [],
      id: ''
    }
  },
  methods: {
    async editHandler () {
      const file = this.item.files;
      const deletes = this.item.deletes;
      const fileDatas = this.item.fileDatas;
      delete this.item.fileDatas;
      delete this.item.files;
      delete this.item.deletes;
      this.item.start = DateUtils.dateFormat(this.item.start, "YYYY-MM-DD");
      this.item.end = DateUtils.dateFormat(this.item.end, "YYYY-MM-DD");
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.putData(`/ab_supplier_contract/${this.item.id}`, this.item);
      if(result&&result.status.code=="200"){
        const s3Auth = await this.HttpServices.authenS3();
        if(s3Auth._info){
          const medias = [];
          const accessToken = s3Auth._info.secret.accessToken;
          if(file&&file.length>0){
            for(const f of file){
              if(f instanceof File){
                let params = new FormData();
                params.append("file", f);
                params.append("title", f.name);
                params.append("file_category", 'supplier-contract');
                const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
                if(resultMedia.media&&resultMedia.media.length>0){
                  const media = resultMedia.media[0];
                  const mediaUpload = {
                    id: media.id,
                    original_name: media.original_name,
                    mime_type: media.mime_type,
                    size: media.size,
                    url: media.url
                  };

                  params = new FormData();
                  const thumbFile = await StringUtils.resizeBlobToFile({file: f, maxSize: 200});
                  params.append("file", thumbFile);
                  params.append("title", thumbFile.name);
                  params.append("file_category", 'supplier-contract');
                  let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
                  if(resultThumb.media&&resultThumb.media.length>0){
                    const mediaThumb = resultThumb.media[0];
                    mediaUpload.id_t = mediaThumb.id;
                    mediaUpload.original_name_t = mediaThumb.original_name;
                    mediaUpload.mime_type_t = mediaThumb.mime_type;
                    mediaUpload.size_t = mediaThumb.size;
                    mediaUpload.url_t = mediaThumb.url;
                  }
                  medias.push(mediaUpload);
                }
              }
            }
          }

          if(fileDatas&&fileDatas.length>0){
            for(const f of fileDatas){
              if(f.id){
                const mediaUpload = {
                  id: f.id,
                  original_name: f.original_name,
                  mime_type: f.mime_type,
                  size: f.size,
                  url: f.url
                };
                if(f.id_t){
                  mediaUpload.id_t = f.id_t;
                  mediaUpload.original_name_t = f.original_name_t;
                  mediaUpload.mime_type_t = f.mime_type_t;
                  mediaUpload.size_t = f.size_t;
                  mediaUpload.url_t = f.url_t;
                }
                medias.push(mediaUpload);
              }
            }
          }
          if(medias.length>0){
            const params = {media: JSON.stringify(medias)};
            await this.HttpServices.postFormData(`/ab_supplier_contract/upload/${this.item.id}`, params);
          }
        }
        if(s3Auth._info){
          const accessToken = s3Auth._info.secret.accessToken;
          if(deletes&&deletes.length>0){
            for(const v of deletes){
              await this.HttpServices.deleteDataS3(v, accessToken);
            }
          }
        }

        // if(file&&file.length>0){
        //   for(const v of file){
        //     const params = new FormData();
        //     params.append("fileUpload", v);
        //     await this.HttpServices.postFormData(`/ab_supplier_contract/upload/${this.item.id}`, params);
        //   }
        // }
        // if(deletes&&deletes.length>0){
        //   await this.HttpServices.putData(`/ab_supplier_contract/delete-files/${this.item.id}`, {deletes: JSON.stringify(deletes)});
        // }
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/supplier-contract');
        });
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
      this.SpinnerService.hideLoader();
    },
    onBackHandler(){
      this.$router.push("/supplier-contract");
    },

    async getItem(){
      const result = await this.HttpServices.getData(`/ab_supplier_contract/${this.id}`);
      if(result&&result.status.code=="200"){
        this.item = result.data;
        if(this.item.start){
          this.item.start = new Date(this.item.start);
        }
        if(this.item.end){
          this.item.end = new Date(this.item.end);
        }
        if(this.item.fileDatas&&this.item.fileDatas.length>0){
          for(const v of this.item.fileDatas){
            v.file_path = `${Config.apiUrl}/resources/download/${v.file_path}?fileName=${v.file_name}`;
          }
        }
        this.item.files = [];
        this.item.deletes = [];
      }else{
        this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูลสัญญาซัพพลายเออร์`, ()=>{
          this.$router.push('/supplier-contract');
        });
      }
    },
  },
  components: {
    formSupplierContact
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
    this.id = await this.SessionStorageUtils.getSession("page-supplier-contract");
    this.suppliers = await this.HttpServices.getMasterData("/master/getSuppliers");
    await this.getItem();
  }
}
</script>
